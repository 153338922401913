import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {API, Hub} from "aws-amplify";
import {Button, Modal} from "antd";
useSubscription.propTypes = {
    subscriptions: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
};

export const filterObjectForNull = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined) {
            acc[key] = value;
        }
        return acc;
    }, {});
}
function useSubscription({subscription, setState}) {
    const [myState, setMyState] = useState({subscriptionError: false})
    useEffect(() => {
        const errorHandler = (error) => {
            console.warn(error);
            setMyState(s => ({...s, subscriptionError: error.message}))
        };
        const unsubscribes = []
        if (subscription.hasOwnProperty('onUpdate')) {
            const updateSub = API.graphql({query: subscription.onUpdate}).subscribe({
                next: ({provider, value}) => {
                    let item = value.data[`onUpdate${subscription.name}`]
                    if (item) {
                        let cleaned = filterObjectForNull(item)
                        setState(s => {

                            if (Array.isArray(s)){
                                let existing = s.find(it => it.id === cleaned.id)
                                if (existing) {

                                    return s.map(it => {
                                        if (it.id === cleaned.id) {
                                            return {
                                                ...it,
                                                ...cleaned
                                            }
                                        }
                                        return it
                                    })
                                } else {
                                    return [...s, cleaned]
                                }
                            }
                            else{
                                return {
                                    ...s,
                                    ...cleaned
                                }
                            }
                        })
                    }
                },
                error: errorHandler
            });
            unsubscribes.push(updateSub.unsubscribe)
        }
        if (subscription.hasOwnProperty('onCreate')) {
            // Subscribe to creation
            const createSub = API.graphql({query: subscription.onCreate}).subscribe({
                next: ({provider, value}) => {

                    const newData = value.data[`onCreate${subscription.name}`]

                    setState(s => {
                        let existing = s.find(it => it.id === newData.id)
                        if (existing) {
                            return s.map(it => {
                                if (it.id === newData.id) {
                                    return {
                                        ...it,
                                        ...newData
                                    }
                                }
                                return it
                            })
                        } else {
                            return [...s, newData]
                        }
                    })
                },
                error: errorHandler
            });
            unsubscribes.push(createSub.unsubscribe)
        }
        if (subscription.hasOwnProperty('onDelete')) {
            // Subscribe to deletion

            const deleteSub = API.graphql({query: subscription.onDelete}).subscribe({
                next: ({provider, value}) => {

                    const id = value.data[`onDelete${subscription.name}`].id


                    setState(s => {

                        return [...s].filter(it => it.id !== id)
                    })
                },
                error: errorHandler
            });
            unsubscribes.push(deleteSub.unsubscribe)
        }
        return () => {
            unsubscribes.forEach(unsubscribe => {
                try{
                   // console.log('unsubscribed')
                    unsubscribe()

                }
                catch (e) {}
            })
        }
    }, [])

    return (
        <Modal footer={null} title="Subscription Error" open={!!myState.subscriptionError} closable={false}>
            <p><small>{myState.subscriptionError}</small></p>
            <p>Please reload the page and try again</p>
            <Button onClick={() => window.location.reload()}>Reload</Button>
        </Modal>
    )

}

export default useSubscription;